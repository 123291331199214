import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@cp/ds/src/components/box';
import { CatalogItemType } from '@common/model/catalog/item/type';
import { Skeleton } from '@cp/ds/src/components/skeleton';
import { styled } from '@cp/ds/src/theme';
import { AdminCatalogItemViewModel } from '@common/model/catalog/item/admin.view';
import { adminCatalogItemApi } from 'src/entities/catalog/item';
import { VacancyCard } from './card';

export interface VacancyListProps extends BoxProps {
  clientId?: number;
  onItemClick?: (model: AdminCatalogItemViewModel) => void;
  renderItemContent?: (model: AdminCatalogItemViewModel) => ReactNode;
}
const Root = styled(Box, { name: 'VacancyList' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  marginBlock: 0,
  paddingInline: 0,
}));

export const VacancyList: React.FC<VacancyListProps> = ({ clientId = 0, onItemClick, renderItemContent, ...props }) => {
  const { data, isLoading } = adminCatalogItemApi.endpoints.getList.useQuery(
    { type: CatalogItemType.Vacancy, clientId, sorters: { createdAt: 'desc' } },
    { skip: !clientId },
  );
  return (
    <Root component="ul" {...props}>
      {isLoading && <Skeleton />}
      {data?.items.map((vacancy) => (
        <VacancyCard component="li" key={vacancy.id} model={vacancy} onActivate={onItemClick}>
          {renderItemContent?.(vacancy)}
        </VacancyCard>
      ))}
    </Root>
  );
};
