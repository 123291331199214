import React, { useMemo } from 'react';
import { ClientType } from '@common/model/client/clientType';
import { ClientTypeIcon, coreClientApi } from 'src/entities/client';
import { useDeclination } from 'src/app/shared/hooks/useDeclination';
import { StatItem } from './statItem';

export const ClientActivity: React.FC<{ clientType: ClientType; title?: string }> = ({ clientType, title }) => {
  useDeclination();
  const now = useMemo(() => new Date().toISOString().slice(0, 10), []);
  const { data, isLoading } = coreClientApi.endpoints.getClients.useQuery({
    type: clientType,
    lastActivityAt: { after: `P7D/${now}` },
    pagination: 1,
  });
  const formatted = data?.totalCount ? Intl.NumberFormat('ru-ru').format(data.totalCount) : '--';
  return <StatItem isLoading={isLoading} label={<ClientTypeIcon type={clientType} />} title={title} value={formatted} />;
};
