import React from 'react';
import { useVacancyDates } from '@cp/entities/vacancy';
import { AdminCatalogItemViewModel } from '@common/model/catalog/item/admin.view';
import { CareerTag } from '@cp/entities/career';
import { Box } from '@cp/ds/src/components/box';
import { Typography } from '@cp/ds/src/components/typography';
import { VacancyCard as BaseCard } from 'src/entities/vacancy';
import { CardProps } from 'src/shared/ui/card';
export interface VacancyCardProps extends CardProps {
  model: AdminCatalogItemViewModel;
  onActivate?: (m: AdminCatalogItemViewModel) => void;
}
export const VacancyCard: React.FC<VacancyCardProps> = ({ model, onActivate, children, ...props }) => {
  const dates = useVacancyDates(model);
  return (
    <BaseCard
      matched={'matchedAt' in model}
      onClick={() => onActivate?.(model)}
      tags={<CareerTag careerId={model.careerId} />}
      title={model.translation?.title || dates}
      {...props}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
        {model.translation?.description && <Typography>{model.translation.description}</Typography>}
        {children}
      </Box>
    </BaseCard>
  );
};
