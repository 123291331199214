import React from 'react';
import { ClientType } from '@common/model/client/clientType';
import { ClientTypeIcon } from 'src/entities/client';
import { useTotalClients } from '../model';
import { StatItem } from './statItem';

export const Specialists = () => {
  const [count, isLoading] = useTotalClients({ type: ClientType.Specialist });
  const formatted = count ? Intl.NumberFormat('ru-ru').format(count) : '--';
  return <StatItem isLoading={isLoading} label={<ClientTypeIcon type={ClientType.Specialist} />} title="Специалисты" value={formatted} />;
};
